import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div class="banner">
      <div class="container">
        <div class="colx1">
          <div class="copy">
            <h3 class="center">
              404 - Page not found
            </h3>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
